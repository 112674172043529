(<template>
  <div class="assignments">
    <div class="assignments__header">
      <div class="assignments__header--left">
        <h1 class="assignments__header-title">{{ $gettext('Interpretations') }}</h1>
        <button class="sk-btn assignments__header-refresh-button"
                @click="updateGridData">{{ $gettext('Update') }}</button>
      </div>
      <div>
        <button class="sk-btn sk-btn--white assignments__header-export-button"
                @click="openExportConfirmationModal">{{ $gettext('Export') }}</button>
      </div>
    </div>
    <div class="assignments__cont">

      <!------- All Tabs ------->
      <div class="assignment-tabs__cont">
        <div class="assignment-tabs__wrapper">
          <multiselect-tab v-for="tab in leftSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.managersList"
                           :items-list="computedManagersList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :stat-needs-update="tab.statNeedsUpdate"
                           :tab="tab"
                           @checkboxeschanged="setTabForUpdate(tab.tab, $event)" />
        </div>
        <div class="assignment-tabs__wrapper">
          <multiselect-tab v-for="tab in rightSideTabs"
                           :key="tab.tab"
                           :preselected-value="tab.managersList"
                           :items-list="computedManagersList"
                           :count="tab.count"
                           :with-avatar="tab.withAvatar"
                           :tab="tab" />
        </div>
      </div>
      <!------- Grid Component ------->
      <ag-grid :column-defs="columnDefs"
               :grid-options="gridOptions"
               :fetch-data-func="fetchDataFunc"
               :success-export-request-func="openManagerExportConsole"
               :row-style-func="rowStyleFunc"
               :grid-data="gridData"
               @interface="getChildInterface" />
    </div>
  </div>
</template>)

<script>
/* eslint-disable vue/no-unused-components */
// For Grid filter components
  import helpers from '@/helpers';
  import {mapActions, mapMutations, mapState} from 'vuex';
  import LanguagePairFilter from '@/components/grid/filters/shared/LanguagePairFilter';
  import SessionTypeFilter from '@/components/grid/filters/shared/SessionTypeFilter';
  import StatusFilter from '@/components/grid/filters/StatusFilter';
  import WSManager from '@/modules/ws_data_module';
  import AssigneesFilter from '@/components/grid/filters/AssigneesFilter';
  import CategoryFilter from '@/components/grid/filters/CategoryFilter';
  import OwnerParticipantFilter from '@/components/grid/filters/OwnerParticipantFilter';
  import IdFilter from '@/components/grid/filters/IdFilter';
  import CaseNumberFilter from '@/components/grid/filters/CaseNumberFilter';
  import BuyerAndBookingRefFilter from '@/components/grid/filters/BuyerAndBookingRefFilter';
  import HintsFilter from '@/components/grid/filters/hint/JobHintsFilter';
  import constants from '@/modules/constants';
  import PageWithGridMixin from '@/mixins/PageWithGridMixin';
  // ---- Cells --- //
  import CStatusChip from '@/components/grid/cells/shared/CStatusChip';
  import CLanguagePair from '@/components/grid/cells/job/CLanguagePair';
  import CStartTime from '@/components/grid/cells/job/CStartTime';
  import CId from '@/components/grid/cells/shared/CId';
  import CInterpreterName from '@/components/grid/cells/job/CInterpreterName';
  import CPersonInfo from '@/components/grid/cells/shared/CPersonInfo';
  import CCreatedAt from '@/components/grid/cells/job/CCreatedAt';
  import CViewers from '@/components/grid/cells/job/CViewers';
  import CHints from '@/components/grid/cells/hints/CHints';
  import CActivity from '@/components/grid/cells/job/CActivity';
  import CSubjectAndCategory from '@/components/grid/cells/job/CSubjectAndCategory';
  import CNotes from '@/components/grid/cells/job/CNotes';
  import CCaseNumber from '@/components/grid/cells/job/CCaseNumber';
  import CBuyerAndBookingRef from '@/components/grid/cells/job/CBuyerAndBookingRef';

  export default {
    asyncData({store}) {
      let promisesArr = [];

      promisesArr = [...promisesArr, store.dispatch('ManagerSocketDataStore/getManagerJobStats')];
      promisesArr = [...promisesArr, store.dispatch('ManagerSocketDataStore/getManagerJobAssignments')];
      promisesArr = [...promisesArr, store.dispatch('ManagerSocketDataStore/getManagerJobActivity')];
      if (!store.state.PostingStore.SubjectStore.categoriesList.length) {
        promisesArr = [...promisesArr, store.dispatch('PostingStore/SubjectStore/getAllCategories')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'language-pair-filter': LanguagePairFilter,
      'assignees-filter': AssigneesFilter,
      'category-filter': CategoryFilter,
      'owner-participant-filter': OwnerParticipantFilter,
      'session-type-filter': SessionTypeFilter,
      'status-filter': StatusFilter,
      'id-filter': IdFilter,
      'case-number-filter': CaseNumberFilter,
      'buyer-booking-ref-filter': BuyerAndBookingRefFilter,
      'hints-filter': HintsFilter,
      'c-status-chip': CStatusChip,
      'c-language-pair': CLanguagePair,
      'c-start-time': CStartTime,
      'c-id': CId,
      'c-interpreter-name': CInterpreterName,
      'c-person-info': CPersonInfo,
      'c-created-at': CCreatedAt,
      'c-viewers': CViewers,
      'c-hints': CHints,
      'c-activity': CActivity,
      'c-category-subject': CSubjectAndCategory,
      'c-notes': CNotes,
      'c-case-number': CCaseNumber,
      'c-buyer-and-booking-ref': CBuyerAndBookingRef,
      'ag-grid': () => import('@/components/grid/GridComponent')
    },
    mixins: [PageWithGridMixin],
    data() {
      return {
        currentView: this.$route.query.view || 'urgent',
        currentPage: Number(this.$route.query.page) || 1,
        filterParams: {
          owners: {
            filter: this.$route.query.owners || '',
            type: 'contains'
          },
        },
        items: this.$store.state.filterItemsAmount || 10,
        ransackParams: {},
        fetchGridDataAction: 'AllAssignmentsStore/getManagerAssignments',
        gridOptions: {
          pagination: true,
          suppressPaginationPanel: true,
          suppressMovableColumns: true,
          suppressColumnVirtualisation: false,
          paginationPageSize: this.$store.state.filterItemsAmount,
          paginationAutoPageSize: false,
          suppressExcelExport: true,
          suppressRowClickSelection: true,
          rowHeight: 65,
          popupParent: null,
          rowStyle: {'max-height': '58px', 'box-shadow': '0 0 4px 0 rgb(0 0 0 / 5%), 0 0 4px 0 rgb(0 0 0 / 5%)'},
          rowModelType: 'infinite',
          // ------ cacheBlockSize Equal to pageSize no caching ------- //
          cacheBlockSize: this.$store.state.filterItemsAmount,
          maxBlocksInCache: 1,
          maxConcurrentDatasourceRequests: 2,
          domLayout: 'autoHeight',
          floatingFiltersHeight: 60
        },
        // ------- Tabs Data ------- //
        tabForUpdate: ''
      };
    },
    computed: {
      ...mapState('AllAssignmentsStore', {
        managerAssignmentsData: (state) => state.managerAssignments || {},
        updateManagerJobGrid: (state) => state.updateManagerJobGrid || '',
      }),
      ...mapState('ManagerSocketDataStore', {
        // -- Assignable Tabs Job Count -- //
        urgentCount: (state) => state.interpretationTabs.urgent.count || 0,
        nextUpCount: (state) => state.interpretationTabs.nextUp.count || 0,
        otherPublishedCount: (state) => state.interpretationTabs.otherPublished.count || 0,
        vipCount: (state) => state.interpretationTabs.vip.count || 0,
        // -- Non-assignable Tabs Job Count -- //
        all: (state) => state.interpretationTabs.all.count || 0,
        allNextUp: (state) => state.interpretationTabs.allNextUp.count || 0,
        allToday: (state) => state.interpretationTabs.allToday.count || 0,
        // -- Assigned managers to Tabs -- //
        urgentManagers: (state) => state.interpretationTabs.urgent.managers || [],
        nextUpManagers: (state) => state.interpretationTabs.nextUp.managers || [],
        otherPublishedManagers: (state) => state.interpretationTabs.otherPublished.managers || [],
        vipManagers: (state) => state.interpretationTabs.vip.managers || [],
        // -- Copy Job Ids -- //
        urgentCopyJobIds: (state) => state.interpretationTabsCopy.urgent?.jobIds || [],
        nextUpCopyJobIds: (state) => state.interpretationTabsCopy.nextUp?.jobIds || [],
        otherPublishedCopyJobIds: (state) => state.interpretationTabsCopy.otherPublished?.jobIds || [],
        vipJobCopyIds: (state) => state.interpretationTabsCopy.vip?.jobIds || [],
        // -- Job Ids -- //
        urgentJobIds: (state) => state.interpretationTabs.urgent?.jobIds || [],
        nextUpJobIds: (state) => state.interpretationTabs.nextUp?.jobIds || [],
        otherPublishedJobIds: (state) => state.interpretationTabs.otherPublished?.jobIds || [],
        vipJobIds: (state) => state.interpretationTabs.vip?.jobIds || [],
        interpretationTabs: (state) => state.interpretationTabs || {},
      }),
      columnDefs() {
        return [
          {
            headerName: this.$gettext('ID'),
            field: 'id',
            maxWidth: 150,
            flex: 1,
            sortable: true,
            floatingFilterComponent: 'id-filter',
            ...this.textFilterNoMenu,
            cellRendererSelector: (params) => {
              return {
                component: 'c-id',
                params: params
              };
            },
            condition: true
          },
          {headerName: this.$gettext('Status'),
           field: 'status',
           width: 160,
           minWidth: 140,
           sortable: true,
           floatingFilterComponent: 'status-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-status-chip',
               params: params
             };
           },
           condition: this.rightSideTabsActive},
          {
            headerName: this.$gettext('Assignee'),
            field: 'assignedManagerUid',
            cellClass: 'hasPopup',
            minWidth: 160,
            maxWidth: 250,
            flex: 1,
            floatingFilterComponent: 'assignees-filter',
            ...this.multiSelectFilterParams,
            cellRendererSelector: (params) => {
              return {
                component: 'c-viewers',
                params: params
              };
            },
            condition: !this.rightSideTabsActive
          },
          {
            headerName: this.$gettext('Assignee and hints'),
            field: 'assignedManagerUid',
            cellClass: 'hasPopup',
            maxWidth: 250,
            flex: 1,
            floatingFilterComponent: 'assignees-filter',
            ...this.multiSelectFilterParams,
            cellRendererSelector: (params) => {
              const combinedParams = {
                ...params,
                hasAwardedInterpreter: this.rightSideTabsActive
              };
              return {
                component: 'c-hints',
                params: combinedParams
              };
            },
            condition: this.rightSideTabsActive
          },
          {headerName: this.$gettext('Assignment time'),
           field: 'startTime',
           maxWidth: 150,
           sortable: true,
           suppressMenu: true,
           filter: 'agDateColumnFilter',
           filterParams: {
             suppressAndOrCondition: true,
             defaultJoinOperator: 'AND',
             buttons: ['reset', 'apply'],
             filterOptions: ['inRange']
           },
           cellRendererSelector: (params) => {
             return {
               component: 'c-start-time',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Language Pair'),
           field: 'lang',
           maxWidth: 250,
           flex: 1,
           floatingFilterComponent: 'language-pair-filter',
           ...this.multiSelectFilterParams,
           cellRendererSelector: (params) => {
             return {
               component: 'c-language-pair',
               params: params
             };
           },
           condition: true},
          {
            headerName: this.$gettext('Hints'),
            field: 'hintsFilter',
            cellClass: 'hasPopup',
            minWidth: this.rightSideTabsActive ? 190 : 280,
            width: 190,
            flex: 1,
            floatingFilterComponent: 'hints-filter',
            ...this.multiSelectFilterParams,
            cellRendererSelector: (params) => {
              const combinedParams = {
                ...params,
                hasAwardedInterpreter: this.rightSideTabsActive
              };
              return {
                component: 'c-hints',
                params: combinedParams
              };
            },
            condition: !this.rightSideTabsActive
          },
          {headerName: this.$gettext('Created at'),
           maxWidth: 120,
           flex: 1,
           sortable: true,
           field: 'createdAt',
           cellRendererSelector: (params) => {
             return {
               component: 'c-created-at',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Owner & participants'),
           field: 'owner',
           flex: 1,
           sortable: true,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'owner-participant-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-person-info',
               params: params
             };
           },
           condition: true},
          {headerName: this.$gettext('Category and Subject'),
           field: 'categoryItemId',
           colId: 'filteredCategoryItemId',
           maxWidth: 300,
           flex: 1,
           floatingFilterComponent: 'category-filter',
           ...this.multiSelectFilterParams,
           floatingFilterComponentParams: {
             useFilteredCategories: true,
             suppressFilterButton: true
           },
           filterParams: {filterOptions: ['equal']},
           cellRendererSelector: (params) => {
             return {
               component: 'c-category-subject',
               params: params
             };
           },
           condition: !this.rightSideTabsActive},
          {headerName: this.$gettext('Category and Subject'),
           field: 'categoryItemId',
           colId: 'unFilteredCategoryItemId',
           maxWidth: 300,
           flex: 1,
           floatingFilterComponent: 'category-filter',
           ...this.multiSelectFilterParams,
           floatingFilterComponentParams: {
             useFilteredCategories: false,
             suppressFilterButton: true
           },
           filterParams: {filterOptions: ['equal']},
           cellRendererSelector: (params) => {
             return {
               component: 'c-category-subject',
               params: params
             };
           },
           condition: this.rightSideTabsActive},
          // ---- Interpreter Column ---- //
          {headerName: this.$gettext('Interpreter'),
           field: 'interpreter',
           sortable: true,
           filter: true,
           minWidth: 200,
           flex: 1,
           ...this.textFilterNoMenu,
           cellRendererSelector: (params) => {
             return {
               component: 'c-interpreter-name',
               params: params
             };
           },
           condition: this.rightSideTabsActive},
          {headerName: this.$gettext('Case Number'),
           field: 'caseNumber',
           cellClass: 'case-number',
           maxWidth: 120,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'case-number-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-case-number',
               params: params
             };
           },
           condition: !this.rightSideTabsActive},
          {headerName: this.$gettext('Buyer/Order ref'),
           field: 'references',
           maxWidth: 120,
           ...this.textFilterNoMenu,
           floatingFilterComponent: 'buyer-booking-ref-filter',
           cellRendererSelector: (params) => {
             return {
               component: 'c-buyer-and-booking-ref',
               params: params
             };
           },
           condition: !this.rightSideTabsActive},
          {headerName: this.$gettext('Notes'),
           field: 'lastAdminDiscussionText',
           minWidth: 250,
           flex: 2,
           cellRendererSelector: (params) => {
             return {
               component: 'c-notes',
               params: params
             };
           },
           condition: true},
        ].filter((column) => column.condition);
      },
      managersList() { return this.$store.state.selectsData.managers; },
      computedManagersList() {
        if (this.managersList.length) {
          return this.managersList.map((manager) => {
            return {
              id: manager.uid,
              name: manager.name ? manager.name : manager.email,
              avatarPath: manager.avatarPath,
            };
          });
        }
        return [];
      },
      gridData() {
        return {
          withDefaultSort: true,
          page: this.currentPage,
          items: this.items,
          ransackParams: this.tabRansackParams,
          filterParams: this.filterParams
        };
      },
      tabRansackParams() {
        switch (this.currentView) {
          case 'urgent':
            return {
              's[status_eq]': 'published',
              's[confirmation_deadline_gteq]': this.startOfTheDay,
              's[confirmation_deadline_lteq]': this.endOfTheDay
            };
          case 'next_up':
            return {
              's[status_eq]': 'published',
              's[confirmation_deadline_gteq]': this.startOfTomorrow,
              's[confirmation_deadline_lteq]': this.endOfTomorrow
            };
          case 'other_published':
            return {
              's[status_eq]': 'published',
              's[confirmation_deadline_gt]': this.endOfTomorrow
            };
          case 'vip':
            return {
              's[status_eq]': 'published',
              's[vip_template_eq]': 'true'
            };

          case 'all_today':
            return {
              's[start_time_gteq]': this.startOfTheDay,
              's[start_time_lteq]': this.endOfTheDay
            };
          case 'all_next_up':
            return {
              's[start_time_gteq]': this.startOfTomorrow,
              's[start_time_lteq]': this.endOfTomorrow
            };
          case 'all':
            return {};
        }
      },
      isUrgent() { return this.currentView === 'urgent'; },
      isNextUp() { return this.currentView === 'next_up'; },
      isVip() { return this.currentView === 'vip'; },
      otherPublished() { return this.currentView === 'other_published'; },
      isForToday() { return this.currentView === 'all_today'; },
      isForTomorrow() { return this.currentView === 'all_next_up'; },
      isAllView() { return this.currentView === 'all'; },
      startOfTheDay() { return this.$moment().startOf('day'); },
      endOfTheDay() { return this.$moment().endOf('day'); },
      startOfTomorrow() {
        return this.$moment(this.startOfTheDay).add(1, 'days');
      },
      endOfTomorrow() {
        const tomorrow = this.$moment(this.endOfTheDay).add(1, 'days');

        if (this.isSaturday(tomorrow)) {
          return this.$moment(tomorrow).add(2, 'days');
        } else if (this.isSunday(tomorrow)) {
          return this.$moment(tomorrow).add(1, 'days');
        } else {
          return tomorrow;
        }
      },
      leftSideTabs() {
        return [
          {
            tab: 'urgent',
            isActive: this.isUrgent,
            title: this.$gettext('Urgent'),
            managersList: this.urgentManagers,
            count: this.urgentCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewUrgentJob
          },
          {
            tab: 'next_up',
            isActive: this.isNextUp,
            title: this.$gettext('Next Up'),
            managersList: this.nextUpManagers,
            count: this.nextUpCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewNextUpJob
          },
          {
            tab: 'other_published',
            isActive: this.otherPublished,
            title: this.$gettext('Other published'),
            managersList: this.otherPublishedManagers,
            count: this.otherPublishedCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewOtherPublishedJob
          },
          {
            tab: 'vip',
            isActive: this.isVip,
            title: this.$gettext('VIP'),
            managersList: this.vipManagers,
            count: this.vipCount,
            withAvatar: true,
            params: this.tabRansackParams,
            onClick: this.setCurrentView,
            catchTabCheckbox: this.catchTabCheckbox,
            statNeedsUpdate: this.isThereNewVipJob
          }
        ];
      },
      rightSideTabs() {
        return [
          {
            tab: 'all_today',
            isActive: this.isForToday,
            title: this.$gettext('All today'),
            managersList: [],
            count: this.allToday,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          },
          {
            tab: 'all_next_up',
            isActive: this.isForTomorrow,
            title: this.$gettext('All next up'),
            count: this.allNextUp,
            params: this.tabRansackParams,
            onClick: this.setCurrentView
          },
          {
            tab: 'all',
            isActive: this.isAllView,
            title: this.$gettext('All'),
            count: this.all,
            onClick: this.setCurrentView
          }
        ];
      },
      rightSideTabsActive() {
        const rightSideTabs = ['all', 'all_next_up', 'all_today'];
        return rightSideTabs.includes(this.currentView);
      },
      isThereNewUrgentJob() {
        return !this.urgentJobIds.every((jobId) => this.urgentCopyJobIds.includes(jobId));
      },
      isThereNewOtherPublishedJob() {
        return !this.otherPublishedJobIds.every((jobId) => this.otherPublishedCopyJobIds.includes(jobId));
      },
      isThereNewNextUpJob() {
        return !this.nextUpJobIds.every((jobId) => this.nextUpCopyJobIds.includes(jobId));
      },
      isThereNewVipJob() {
        return !this.vipJobIds.every((jobId) => this.vipJobCopyIds.includes(jobId));
      },
    },
    watch: {
      updateManagerJobGrid() {
        this.updateGridData();
      }
    },
    methods: {
      ...mapMutations('ManagerSocketDataStore', [
        'setCopyUrgentStats',
        'setCopyNextUpStats',
        'setCopyOtherPublishedStats',
        'setCopyVipStats'
      ]),
      ...mapActions('ManagerSocketDataStore', ['updateManagerJobAssignment']),
      rowStyleFunc(params) {
        // -----default margin for rounded rows--------//
        const rowStyle = {};
        const data = {...params.data};
        const {backgroundColor} = helpers.getJobInfo.jobPriceTemplateStyle(this, data.pricingTemplateUiColor);
        rowStyle.backgroundColor = backgroundColor;
        return rowStyle;
      },
      sendExportRequest({startDate, endDate}) {
        const ransackParams = this.tabRansackParams;
        ransackParams.export = 'xlsx';
        if (startDate) ransackParams['s[actual_created_at_gteq]'] = startDate;
        if (endDate) ransackParams['s[actual_created_at_lteq]'] = endDate;
        this.$options.childInterface.sendExportRequest({ransackParams});
      },
      openManagerExportConsole() {
        const managersUrl = constants.API_PATH + '/management_console/exports';
        window.open(managersUrl, '_blank').focus();
      },
      openExportConfirmationModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'export-confirmation-modal',
          width: 660,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Export Jobs'),
            text: this.$gettext('I confirm the date range of the jobs is no longer than a week.'),
            context: this,
            modalCallback: this.sendExportRequest,
            btnTexts: {
              submitBtnText: this.$gettext('Export'),
              cancelBtnText: this.$gettext('Cancel')
            },
          }
        });
      },
      setTabForUpdate(tabId, event) {
        this.tabForUpdate = tabId;
        this.catchTabCheckbox(event);
      },
      setCurrentView(view, ransackParams) {
        this.currentView = view;
        this.ransackParams = ransackParams;
        this.updateGridData();
        this.updateTabStat();
      },
      updateTabStat() {
        switch (this.currentView) {
          case 'urgent':
            this.setCopyUrgentStats({
              ids: this.urgentJobIds,
              count: this.interpretationTabs.urgent.count,
            });
            break;
          case 'other_published':
            this.setCopyOtherPublishedStats({
              ids: this.otherPublishedJobIds,
              count: this.interpretationTabs.otherPublished.count,
            });
            break;
          case 'next_up':
            this.setCopyNextUpStats({
              ids: this.nextUpJobIds,
              count: this.interpretationTabs.nextUp.count,
            });
            break;
          case 'vip':
            this.setCopyVipStats({
              ids: this.vipJobIds,
              count: this.interpretationTabs.vip.count,
            });
            break;
        }
      },
      catchTabCheckbox(value) {
        const assignments = {};
        const joinedValue = value.join(',');
        switch (this.tabForUpdate) {
          case 'urgent':
            assignments.urgent = joinedValue;
            break;
          case 'next_up':
            assignments.nextUp = joinedValue;
            break;
          case 'other_published':
            assignments.otherPublished = joinedValue;
            break;
          case 'vip':
            assignments.vip = joinedValue;
            break;
        }
        this.updateManagerJobAssignment({assignments});
      },
      isSaturday(momentDate) { return momentDate.day() === 6; },
      isSunday(momentDate) { return momentDate.day() === 7; },
      subscribeToManagerChannels(route, vm) {
        if (vm.$webSocket) {
          vm.$root.$managersAssignmentsChannel = vm.$webSocket.subscribe((data) => {
            WSManager.setDataFromManagersAssignmentsChannel(vm, data);
          }, 'JobScopesManagersAssignmentsChannel');
          vm.$root.$managersJobStatsChannel = vm.$webSocket.subscribe((data) => {
            WSManager.setDataFromManagerJobStatsChannel(vm, data);
          }, 'ManagersJobStatisticsChannel');
          vm.$root.$managersJobActivityChannel = vm.$webSocket.subscribe((data) => {
            WSManager.setDataFromManagerJobActivityChannel(vm, data);
          }, 'JobsManagersActivityChannel');
        }
      },
      unsubscribeFromManagerChannels(route, vm) {
        if (vm.$root.$managersAssignmentsChannel && vm.$webSocket) {
          vm.$root.$managersAssignmentsChannel.unsubscribe();
        }
        if (vm.$root.$managersJobStatsChannel && vm.$webSocket) {
          vm.$root.$managersJobStatsChannel.unsubscribe();
        }
        if (vm.$root.$managersJobActivityChannel && vm.$webSocket) {
          vm.$root.$managersJobActivityChannel.unsubscribe();
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.subscribeToManagerChannels(to, vm);
      });
      if (to.query.view == {} || to.query.view == null || to.query.view == undefined) {
        next((vm) => {
          return {path: to.path, query: {view: 'urgent', page: 1, items: vm.$store.state.filterItemsAmount}};
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('ManagerSocketDataStore/clearStore');
      this.$store.commit('PostingStore/SubjectStore/clearStore');
      this.unsubscribeFromManagerChannels(from, this);
      next();
    },
    childInterface: {
      updateData: () => {},
      sendExportRequest: () => {},
    },
  };
</script>
<style scoped>
.assignments {
  margin: 10px 20px;
}

.assignments__cont {
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 5%);
}

.assignment-tabs__cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3d5de;
}

.assignment-tabs__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.assignments__header {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.assignments__header--left {
  display: flex;
  align-items: center;
}

.assignments__header-title {
  font-size: 24px;
}

.assignments__header-refresh-button,
.assignments__header-export-button {
  padding-right: 15px;
  padding-left: 35px;
  background-position: 9.55% 50%;
  background-size: 22px auto;
  background-repeat: no-repeat;
}

.assignments__header-refresh-button {
  margin-left: 10px;
  border: 1px solid #d3d5db;
  border-radius: 8px;
  background-color: #fff;
  background-image: url(~@assets/imgs/undefined_imgs/refresh_icon.svg);
}

.assignments__header-refresh-button:hover {
  background-color: #dadada;
}

.assignments__header-export-button {
  background-image: var(--assignment-header-export-icon);
  background-position: 5% 50%;
}
</style>
