<template>
  <section class="category-subject">
    <sk-select :default-value="categoryDefaultValue"
               :items-list="filteredCategories"
               class="category-subject__select"
               :ignore-categories="true"
               :preselected-value="filterCategory"
               @csvaluechanged="onInputBoxChanged" />
    <button class="category-subject__reset-icon"
            @click="resetCategory"></button>
  </section>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        filterCategory: ''
      };
    },
    computed: {
      useFilteredCategories() { return this.params.useFilteredCategories || false; },
      ...mapState('PostingStore/SubjectStore', {
        categories: (state) => state.allCategories || []
      }),
      ...mapState('AllAssignmentsStore', {
        managerAssignmentsData: (state) => state.managerAssignments || {},
      }),
      categoryItemIds() { return this.managerAssignmentsData?.category_item_ids; },
      filteredCategories() {
        if (this.useFilteredCategories) {
          const categories = [];
          this.categories?.forEach((category) => {
            const filteredSingleCategoryItems = category.items?.filter((categoryItem) => {
              const itemIds = this.categoryItemIds?.filter((categoryItemId) => {
                return categoryItemId == categoryItem.id;
              });
              return itemIds && itemIds?.length > 0 ? categoryItem : false;
            });

            if (filteredSingleCategoryItems && filteredSingleCategoryItems.length > 0) {
              const singleCategory = {
                id: '',
                name: '',
                items: []
              };
              singleCategory.id = category.id;
              singleCategory.name = category.name;
              singleCategory.items.push(...filteredSingleCategoryItems);
              singleCategory ? categories.push(singleCategory) : null;
            }

            return filteredSingleCategoryItems && filteredSingleCategoryItems?.length > 0;
          });
          return categories;
        } else {
          return this.categories;
        }
      },
      categoryDefaultValue() { return this.$gettext('Select a category'); },
    },
    methods: {
      onInputBoxChanged(value) {
        this.filterCategory = value;
        if (this.filterCategory === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.filterCategory = '';
        }
      },
      resetCategory() {
        this.filterCategory = '';
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('', '');
        });
      }
    }
  };
</script>

<style>
.category-subject .sk-select {
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.category-subject .sk-select__list {
  width: fit-content;
  max-width: 250px;
}

.category-subject {
  display: flex;
  width: 100%;
}

.category-subject__select {
  width: 80%;
}

.category-subject__reset-icon {
  align-self: center;
  width: 20px;
  height: 25px;
  margin-left: 5px;
  background-image: url(~@assets/imgs/undefined_imgs/close_x_icon_blue.svg);
  background-position: 50% 50%;
  background-size: 13px auto;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>
