(<template>
  <div class="category-subject is-progress-bar"
       :class="{'in-progress': progressActive, 'pointer': !noActionOnClick}"
       @click="onCellClick">
    <div class="category-subject__wrapper">
      <p>{{ subject }}</p>
    </div>
    <p class="subject">{{ category }}</p>
  </div>
</template>)

<script>
  import ManagerJobEditInfoMixin from '@/mixins/ManagerJobEditInfoMixin';

  export default {
    mixins: [ManagerJobEditInfoMixin],
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      subject() { return this.params.data?.subject; },
      categoryName() { return this.params.data?.categoryName; },
      categoryItemName() { return this.params.data?.categoryItemName; },
      category() { return this.categoryName ? this.categoryName + ' - ' + this.categoryItemName : '-'; },
      noActionOnClick() { return !!this.propData?.noActionOnClick; },
    },
    methods: {
      onCellClick() {
        if (!this.noActionOnClick) this.openEditAssignmentInformation();
      },
    }
  };
</script>
<style scoped>
.category-subject {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}

.category-subject__wrapper {
  display: flex;
  align-items: center;
}

.subject {
  overflow: hidden;
  width: 100%;
  color: #555;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
