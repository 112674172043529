(<template>
  <div class="interpreter">
    <div class="interpreter-info">
      <div class="interpreter__wrapper">
        <div ref="emailAndPhoneCell"
             class="interpreter__email-phone">
          <span v-if="interpreterPhone"
                class="phone-icon"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(interpreterPhone, $event)"
                @click="copyToClipboard(interpreterPhone)"></span>
          <span v-else
                class="phone-icon--grey"></span>
          <span v-if="interpreterEmail"
                class="email-icon"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip(interpreterEmail, $event)"
                @click="copyToClipboard(interpreterEmail)"></span>
          <span v-else
                class="email-icon---grey"></span>
        </div>
        <p class="interpreter-name">{{ interpreter }}</p>
      </div>
      <p class="interpreter__qualification"
         @mouseleave="closeTooltip"
         @mouseover="openTooltip(interpreterQualification, $event)">{{ interpreterQualification }}</p>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    computed: {
      interpreter() { return this.params.data?.interpreter?.name || '-'; },
      interpreterEmail() { return this.params.data?.interpreter?.email; },
      interpreterPhone() { return this.params.data?.interpreter?.phone; },
      interpreterQualification() { return this.params.data?.interpreter?.qualification || '-'; }
    },
    methods: {
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); },
      copyToClipboard(value) { navigator.clipboard.writeText(value); }
    }
  };
</script>

<style scoped>
.interpreter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.interpreter-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.interpreter__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.interpreter-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.interpreter__email-phone {
  display: flex;
  margin-right: 5px;
}

.phone-icon,
.email-icon {
  width: 14px;
  height: 17px;
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  cursor: pointer;
}

.phone-icon {
  margin-right: 5px;
  background-image: url(~@assets/imgs/salita/job_grid/phone_black_icon.svg);
}

.email-icon {
  background-image: url(~@assets/imgs/salita/job_grid/email_black_icon.svg);
}

.interpreter__qualification {
  overflow: hidden;
  width: 100%;
  color: #555;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
</style>
