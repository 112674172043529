<template>
  <div class="case-number">
    <p>{{ caseNumber }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() {
        return this.params?.data ? this.params?.data : this.data;
      },
      caseNumber() { return this.propData?.caseNumber; }
    }
  };
</script>

<style scoped>
.case-number {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
