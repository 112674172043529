// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/job_grid/phone_black_icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/job_grid/email_black_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.interpreter[data-v-2ebbab6e] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  height: 100%;\n}\n.interpreter-info[data-v-2ebbab6e] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  overflow: hidden;\n}\n.interpreter__wrapper[data-v-2ebbab6e] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n}\n.interpreter-name[data-v-2ebbab6e] {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.interpreter__email-phone[data-v-2ebbab6e] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-right: 5px;\n}\n.phone-icon[data-v-2ebbab6e],\n.email-icon[data-v-2ebbab6e] {\n  width: 14px;\n  height: 17px;\n  background-position: 50% 50%;\n  background-size: 100% auto;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n.phone-icon[data-v-2ebbab6e] {\n  margin-right: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.email-icon[data-v-2ebbab6e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.interpreter__qualification[data-v-2ebbab6e] {\n  overflow: hidden;\n  width: 100%;\n  color: #555;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
