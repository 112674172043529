<template>
  <section class="hints-filter">
    <hint-select v-for="hint in hintFilters"
                 :key="hint.key"
                 :items-list="hint.selectItems"
                 :class="[hint.classes]"
                 :hint-icon="hint.icon"
                 :preselected-value="hint.value"
                 @csvaluechanged="hint.onChange" />
  </section>
</template>

<script>
  import HintSelect from '@/components/grid/filters/hint/HintSelect';

  export default {
    components: {
      'hint-select': HintSelect
    },
    data() {
      return {
        status: [],
        standby: '',
        sessionType: '',
        autoAward: '',
        invitationStatus: '',
        gender: '',
        directJob: '',
        confirmationBy: '',
        alternativeLanguage: '',
        qualification: '',
        hadWithdrawal: '',
      };
    },
    computed: {
      hintFilters() {
        return [
          {
            key: Math.random(),
            classes: ['session-type'],
            selectItems: this.sessionTypes,
            icon: 'video_preference_grey_no_reset',
            value: this.sessionType,
            onChange: this.onSessionByChanged
          },
          {
            key: Math.random(),
            classes: ['invitation-status'],
            selectItems: this.invitationStatusOptions,
            icon: 'invite_ongoing_grey',
            value: this.invitationStatus,
            onChange: this.onInvitationStatusChanged
          },
          {
            key: Math.random(),
            classes: ['withdrawal-status'],
            selectItems: this.withdrawalOptions,
            icon: 'application-warning--grey',
            value: this.hadWithdrawal,
            onChange: this.onHadWithdrewChanged
          },
          {
            key: Math.random(),
            classes: ['auto-award'],
            selectItems: this.autoAwardOptions,
            icon: 'auto_award_off',
            value: this.autoAward,
            onChange: this.onAutoAwardChanged
          },
          {
            key: Math.random(),
            classes: ['standby'],
            selectItems: this.standbyOptions,
            icon: 'not_suitable_for_standby',
            value: this.standby,
            onChange: this.onStandByChanged
          },
          {
            key: Math.random(),
            classes: ['confirmation'],
            selectItems: this.confirmationOptions,
            icon: 'normal_confirmation',
            value: this.confirmationBy,
            onChange: this.onConfirmationByChanged
          },
          {
            key: Math.random(),
            classes: ['gender'],
            selectItems: this.genderOptions,
            icon: 'no_gender_preference',
            value: this.gender,
            onChange: this.onGenderChanged
          },
          {
            key: Math.random(),
            classes: ['direct-job'],
            selectItems: this.directJobOptions,
            icon: 'no_interpreter_preference',
            value: this.directJob,
            onChange: this.onDirectJobChanged
          },
          {
            key: Math.random(),
            classes: ['alternative-language'],
            selectItems: this.alternativeLanguageOptions,
            icon: 'no_alternative_language',
            value: this.alternativeLanguage,
            onChange: this.onAlternativeLangChanged
          },
          {
            key: Math.random(),
            classes: ['qualification'],
            selectItems: this.qualificationOptions,
            icon: 'no_qualification_preference',
            value: this.qualification,
            onChange: this.onQualificationChanged
          },
        ];
      },
      sessionTypes() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'video_preference_grey_no_reset'},
          {id: 'sessionType--phone', name: this.$gettext('Phone'), iconClass: 'phone_no_requirement'},
          {id: 'sessionType--in_person', name: this.$gettext('In-person'), iconClass: 'in_person_no_requirement'},
          {id: 'sessionType--video', name: this.$gettext('Video'), iconClass: 'video_no_requirement'}
        ];
      },
      standbyOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'not_suitable_for_standby'},
          {id: 'standBy--1', name: this.$gettext('Can Standby'), iconClass: 'suitable_for_standby'},
          {id: 'standBy--0', name: this.$gettext('No Standby'), iconClass: 'not_suitable_for_standby'}
        ];
      },
      autoAwardOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'auto_award_off'},
          {id: 'autoAward--true', name: this.$gettext('Auto award On'), iconClass: 'auto_award_on'},
          {id: 'autoAward--false', name: this.$gettext('Auto award Off'), iconClass: 'auto_award_off'}
        ];
      },
      invitationStatusOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'invite_ongoing_grey'},
          {id: 'invitationStatus--10', name: this.$gettext('On going'), iconClass: 'auto_invite_on_infinite_off_ongoing'},
          {id: 'invitationStatus--20', name: this.$gettext('Paused'), iconClass: 'auto_invite_off_infinite_off_paused'},
          {id: 'invitationStatus--30', name: this.$gettext('Finished'), iconClass: 'auto_invite_on_infinite_off_finished'},
          {id: 'invitationStatus--40', name: this.$gettext('Stopped'), iconClass: 'auto_invite_off_infinite_off_disabled'}
        ];
      },
      genderOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'no_gender_preference'},
          {id: 'genderType--male', name: this.$gettext('Male'), iconClass: 'male_no_reset'},
          {id: 'genderType--female', name: this.$gettext('Female'), iconClass: 'female_no_reset'}
        ];
      },
      directJobOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'no_interpreter_preference'},
          {id: 'directJob--1', name: this.$gettext('Specific interpreter specified'), iconClass: 'has_direct_interpreter_no_reset'},
          {id: 'directJob--0', name: this.$gettext('No specific interpreter specified'), iconClass: 'no_interpreter_preference'}
        ];
      },
      confirmationOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'normal_confirmation'},
          {id: 'confirmationBy--1', name: this.$gettext('Confirmation required'), iconClass: 'scheduled_confirmation'},
          {id: 'confirmationBy--0', name: this.$gettext('No confirmation'), iconClass: 'normal_confirmation'}
        ];
      },
      alternativeLanguageOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'no_alternative_language'},
          {id: 'alternativeLanguage--1', name: this.$gettext('Alternative language specified'), iconClass: 'has_alternative_language_no_rest'},
          {id: 'alternativeLanguage--0', name: this.$gettext('No alternative language'), iconClass: 'no_alternative_language'}
        ];
      },
      qualificationOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'no_qualification_preference'},
          {id: 'qualification--true', name: this.$gettext('Qualification specified'), iconClass: 'qualification_specified_no_reset'},
          {id: 'qualification--false', name: this.$gettext('No qualification'), iconClass: 'no_qualification_preference'}
        ];
      },
      withdrawalOptions() {
        return [
          {id: '-1', name: this.$gettext('Clear'), iconClass: 'application-warning--grey'},
          {id: 'interpreterWithdrew--true', name: this.$gettext('Had withdrawal'), iconClass: 'application-warning--orange'},
          {id: 'interpreterWithdrew--false', name: this.$gettext('No withdrawal'), iconClass: 'application-warning--black'}
        ];
      },
      statusValue() { return this.$gettext('Select a status'); },

    },
    methods: {
      onMultiSelectChanged(value) {
        this.status = [...value];
        if (this.status === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onStandByChanged(value) {
        // reset filter value
        if (value == '-1') this.standby = '';
        else this.standby = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onSessionByChanged(value) {
        // reset filter value
        if (value == '-1') this.sessionType = '';
        else this.sessionType = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onAutoAwardChanged(value) {
        // reset filter value
        if (value == '-1') this.autoAward = '';
        else this.autoAward = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onInvitationStatusChanged(value) {
        // reset filter value
        if (value == '-1') this.invitationStatus = '';
        else this.invitationStatus = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onGenderChanged(value) {
        // reset filter value
        if (value == '-1') this.gender = '';
        else this.gender = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onDirectJobChanged(value) {
        // reset filter value
        if (value == '-1') this.directJob = '';
        else this.directJob = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onConfirmationByChanged(value) {
        // reset filter value
        if (value == '-1') this.confirmationBy = '';
        else this.confirmationBy = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onAlternativeLangChanged(value) {
        // reset filter value
        if (value == '-1') this.alternativeLanguage = '';
        else this.alternativeLanguage = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onQualificationChanged(value) {
        // reset filter value
        if (value == '-1') this.qualification = '';
        else this.qualification = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onHadWithdrewChanged(value) {
        // reset filter value
        if (value == '-1') this.hadWithdrawal = '';
        else this.hadWithdrawal = value;

        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('eq', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.status = '';
        }
      }
    }
  };
</script>

<style>
.hints-filter .sk-select {
  background-color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.hints-filter {
  display: flex;
  width: 100%;
}

.hints-filter > *:not(:last-child) {
  margin-right: 2px;
}

.standby .sk-select,
.standby .sk-select.is-opened,
.session-type .sk-select,
.session-type .sk-select.is-opened,
.auto-award .sk-select,
.auto-award .sk-select.is-opened,
.invitation-status .sk-select,
.invitation-status .sk-select.is-opened,
.gender .sk-select,
.gender .sk-select.is-opened,
.confirmation .sk-select,
.confirmation .sk-select.is-opened,
.alternative-language .sk-select,
.alternative-language .sk-select.is-opened,
.withdrawal-status .sk-select,
.withdrawal-status .sk-select.is-opened,
.direct-job .sk-select,
.direct-job .sk-select.is-opened {
  width: 22px;
  height: 0;
  border: none;
}
</style>
