// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/close_x_icon_blue.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.category-subject .sk-select {\n  background-color: #fff;\n  font-weight: normal;\n  font-size: 13px;\n}\n.category-subject .sk-select__list {\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  max-width: 250px;\n}\n.category-subject {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n}\n.category-subject__select {\n  width: 80%;\n}\n.category-subject__reset-icon {\n  -ms-flex-item-align: center;\n      align-self: center;\n  width: 20px;\n  height: 25px;\n  margin-left: 5px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 13px auto;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
