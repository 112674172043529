(<template>
  <div class="activity__cont">
    <p>{{ lastActivity }}</p>
    <p class="activity__last-created-at">{{ lastActivityAt }}</p>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    computed: {
      lastActivityType() { return this.params.data?.lastActivityType; },
      lastActivityAt() { return this.$moment(this.params.data?.lastActivityCreatedAt).format('DD.MM.YYYY, HH:mm'); },
      lastActivity() { return helpers.getJobInfo.lastActivity(this, this.lastActivityType); }
    }
  };
</script>
<style scoped>
.activity__cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 10px 2px;
  border-radius: 8px;
}

.activity__last-created-at {
  color: #555;
}
</style>
