<template>
  <section class="status-filter">
    <sk-multiselect :items-list="jobStatuses"
                    :preselected-value="status"
                    :default-value="statusValue"
                    @checkboxeschanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        status: []
      };
    },
    computed: {
      jobStatuses() {
        return [
          {id: 'published', name: this.$gettext('Published')},
          {id: 'applied', name: this.$gettext('Applied')},
          {id: 'invited', name: this.$gettext('Invited')},
          {id: 'accepted', name: this.$gettext('Accepted')},
          {id: 'in_progress', name: this.$gettext('In progress')},
          {id: 'withdrawn', name: this.$gettext('Withdrawn')},
          {id: 'cancelled', name: this.$gettext('Cancelled')},
          {id: 'closed', name: this.$gettext('Closed')},
          {id: 'finished', name: this.$gettext('Finished')}
        ];
      },
      statusValue() { return this.$gettext('Select a status'); },

    },
    methods: {
      onInputBoxChanged(value) {
        this.status = [...value];
        if (this.status === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.status = '';
        }
      }
    }
  };
</script>

<style>
.status-filter .sk-select {
  background-color: #fff;
}

.status-filter .sk-select__list {
  width: fit-content;
}

.status-filter .sk-select__value {
  color: #333;
  font-weight: normal;
  font-size: 13px;
}

.status-filter {
  width: 100%;
}
</style>
