(<template>
  <div class="buyer-booking-ref"
       @mouseleave="closeTooltip"
       @mouseover="openTooltip(referencesHint, $event)">
    <p class="booking-ref overflow-ellipsis">{{ bookingReference }}</p>
    <div class="second-row__wrapper">
      <p class="payment-booking-ref">{{ paymentBookingReference }}</p>
    </div>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      bookingReference() { return this.propData?.bookingReference; },
      paymentBookingReference() { return this.propData?.paymentBookingReference; },
      referencesHint() { return `${this.bookingReference} <br> ${this.paymentBookingReference}`; }
    },
    methods: {
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); }
    }
  };
</script>
<style scoped>
.buyer-booking-ref {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.second-row__wrapper {
  display: flex;
  align-items: center;
}
</style>
